import "./FooterStyles.css";
import React from "react";
import { FaHome, FaMailBulk, FaLinkedinIn, FaGithub } from "react-icons/fa";
const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="left">
          <div className="location">
            <FaHome
              size={20}
              style={{ color: "#F8D90F", marginRight: "2rem" }}
            />
            <div >
              <p style={{ fontSize: "20px" }} >SF Bay Area</p>
              <p style={{ fontSize: "20px" }}>California</p>
            </div>
          </div>
          <div className="phone">
            <h4></h4>
          </div>
          <div className="email">
            <h4 className="email-text">
              <FaMailBulk
                size={20}
                style={{ color: "#F8D90F", marginRight: "2rem" }}
              />
              moshfeqshahrani@gmail.com
            </h4>
          </div>
        </div>

        <div className="right">
          <h4 style={{ color: "#F8D90F" }}>Links</h4>
          <div className="social">
            <a
              href={"https://github.com/Moshahrani"}
            >
              <FaGithub size={30} />
            </a>
            <a href={"https://www.linkedin.com/in/moshfeq-shahrani-227608201/"}>
              <FaLinkedinIn size={30} />
            </a>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p className="copy" style={{ fontSize: "16px" }}>
          Copyright © {currentYear}, Moshfeq
        </p>
      </div>
    </div>
  );
};

export default Footer;
