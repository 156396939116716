import pro1 from "../assets/project1.png";
import pro2 from "../assets/project2.png";
import pro3 from "../assets/project3.png";
import ProjectSocial from "../assets/projectsocial.gif";
import A2B from "../assets/A2B.gif";
import PropertyManagement from "../assets/property.gif";
import RealEstate from "../assets/realEstate.gif";

const ProjectCardData = [
  {
    imgsrc: A2B,
    title: "Client Website",
    text: "Logistics website for a freight/brokerage client",
    view: "https://a2b-link.com/",
    source: "https://github.com/Moshahrani/A2BLink",
    tech: ["React", "Bootstrap", "HTML", "CSS"],
  },
  {
    imgsrc: ProjectSocial,
    title: "Project Social",
    text: " Social media application. Application includes multiple social media features.",
    view: "https://project-socialmedia.herokuapp.com/login",
    source: "https://github.com/Moshahrani/Project-Social",
    tech: ["Next.js", "Semantic-UI-React", "Socket.io", "MongoDB"],
  },
  {
    imgsrc: RealEstate,
    title: "Client Website",
    text: "Personal Website for Real Estate Agent in the Bay Area",
    view: "https://shafiqshahrani.com/",
    source: "https://github.com/Moshahrani/real-estate-website",
    tech: ["React", "Bootstrap", "CSS", "Zillow API"],
  },
  {
    imgsrc: PropertyManagement,
    title: "Client Website",
    text: "Property management website for an Upwork client",
    view: "https://hkpropertymng.com/",
    tech: ["Javascript", "HTML", "CSS"],
  },
];

export default ProjectCardData;
