import React from "react";
import "./index.css";
import Home from "./routes/Home";
import About from "./routes/About";
import Project from "./routes/Project";
import Contact from "./routes/Contact";
import Navbar from "./components/Navbar";
import { Route, Routes } from "react-router-dom";

// function App() {
//   return (
//     <>
//       <Routes>
//         <Route path="/" element={<Home />} />
//         <Route path="/project" element={<Project />} />
//         <Route path="/about" element={<About />} />
//         <Route path="/contact" element={<Contact />} />
//       </Routes>
//     </>
//   );
// }
function App() {
  return (
    <>
      {/* <nav>
        <ul>
          <li><a href="#home">Home</a></li>
          <li><a href="#project">Projects</a></li>
          <li><a href="#about">About Me</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
      </nav> */}
      <Navbar />
      <div className="content">
        <div id="home"><Home /></div>
        <div id="project"><Project /></div>
        <div id="about"><About /></div>
        <div id="contact"><Contact /></div>
      </div>
    </>
  );
}

export default App;
