import "./FormStyles.css";
import React from "react";

const Form = () => {
  return (
    <div className="outline">
      <p className="chat">Let's have a chat.</p>
      <form>
        <div className="form">
          <label>Your Name:</label>
          <input type="text"></input>
          <label>Email:</label>
          <input type="email"></input>
          <label>Message:</label>
          <textarea rows="6" placeholder="Type your message here" />
          <button className="btn">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default Form;
