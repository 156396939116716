import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import AboutContent from "../components/AboutContent";

const About = () => {
  return (
    <div>
      {/* <Navbar /> */}
      <AboutContent key={Date.now()} />
      {/* <Footer /> */}
    </div>
  );
};

export default About;
