import "./WorkCardStyles.css";
import React from "react";
import { NavLink } from "react-router-dom";
import ProjectCardData from "./WorkCradData";

const WorkCard = (props) => {
  return (
    <div className="project-card">
      <div className="image-container">
        <img
          src={props.imgsrc}
          alt="gif of website/application functionality"
          className="project-image"
        />
      </div>
      <h2 className="project-title">{props.title}</h2>
      <div className="pro-details">
        <p>{props.text}</p>
        <div className="techStack">
          <p className="tech">
            {props.tech.map((i, index) => (
              <p key={index}> {i} </p>
            ))}
          </p>
        </div>
      </div>
      <div className="pro-btns">
        <a href={props.view} target="_blank" rel="noreferrer">
          <button className="btn">View</button>
        </a>
        <a href={props.source} target="_blank" rel="noreferrer">
          <button className="btn">Source</button>
        </a>
      </div>
    </div>
  );
};


export default WorkCard;
