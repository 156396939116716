import "./AboutContentStyles.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ProfessionalPhoto from "../assets/ProfessionalPhoto.png";

const AboutContent = () => {
  const [click, setClick] = useState(false);

  useEffect(() => {
    const handleMouseMove = (e) => {
      const image = document.querySelector(".img");
      if (!image) return; // Add this line to check if the image exists

      const moveFactor = 0.05;

      const x = e.clientX * moveFactor;
      const y = e.clientY * moveFactor;

      image.style.transform = `translate(${x}px, ${y}px)`;
    };

    document.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const scrollTo = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });
    setClick(false);  
  };

  return (
    <div className="about">
      <div className="left">
        <h1>Who Am I?</h1>
        {/* <p>
          I'm a front-end developer with a background in psychology and sales
          which puts me in a unique position to work in a team-environment.
        </p> */}
        <p>
          I'm a full-stack developer with a diverse background in psychology and
          sales, enabling me to excel in team-oriented environments and create
          engaging user experiences. My passion for understanding human
          behavior, combined with my strong communication and interpersonal
          skills, allows me to effectively collaborate with team members and
          clients alike.
          <br />
          Continually seeking to expand my skillset, I stay up-to-date with the
          latest web technologies and design trends, ensuring my work remains
          fresh and relevant.
        </p>
        <Link to="/" onClick={() => { scrollTo('contact'); }}>
          <button className="btn">Contact</button>
        </Link>
      </div>

      <div className="right">
        <div className="img-container">
          <div className="img-stack top">
            <img src={ProfessionalPhoto} className="img" alt="true" />
          </div>
          <div className="circle"></div>
        </div>
      </div>
    </div>
  );
};

export default AboutContent;
