import React from "react";

const Ticker = () => {
  const technologies = [
    "MongoDB",
    "React",
    "Next.js",
    "Django",
    "Bootstrap",
    "Node.js",
    "CircleCI",
    "Jest",
    "Heroku",
    "AWS",
    "Redux",
    "Socket.io",
    "Express",
    "MySQL",
    "PostgreSQL",
  ];
  const doubleTechnologies = [...technologies, ...technologies];

  return (
    <div className="ticker-wrap">
      <div className="ticker">
        {technologies.map((tech, index) => (
          <div key={index} className="ticker__item">{tech}</div>
        ))}
        {technologies.map((tech, index) => (
          <div key={index + 'dup'} className="ticker__item">{tech}</div>
        ))}
      </div>
    </div>
  );
};
export default Ticker;
