import "./NavbarStyles.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const [color, setColor] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 100) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);

  useEffect(() => {
    if (!click) {
      window.scrollTo(0, 0);
    }
  }, [click]);

  const scrollTo = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });
    setClick(false);  
  };


  return (
    <div className={color ? "header header-bg" : "header"}>
      <ul className={click ? "nav-menu active" : "nav-menu"}>
        <li>
          <Link to="/" onClick={() => { scrollTo('welcome'); }}>
            Welcome
          </Link>
        </li>
        <li>
          <Link to="/" onClick={() => { scrollTo('project'); }}>
            Applications
          </Link>
        </li>
        <li>
          <Link to="/" onClick={() => { scrollTo('about'); }}>
            About
          </Link>
        </li>
        <li>
          <Link to="/" onClick={() => { scrollTo('contact'); }}>
            Contact
          </Link>
        </li>
      </ul>
      <div className="hamburger" onClick={handleClick}>
        {click ? (
          <FaTimes size={40} style={{ color: "#fff" }} />
        ) : (
          <FaBars size={40} style={{ color: "#fff" }} />
        )}
      </div>
    </div>
  );
};


export default Navbar;
